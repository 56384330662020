import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const loginWithGoogle = async (): Promise<void> => {
  window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google-login`;
};
export const fetchUserData = async () => {
  try {
    const response = await api.get('/api/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export default api;
import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useGoals } from '../GoalsContext';
import { useTranslation } from 'react-i18next';

interface GoalsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  amount: number;
  startingDate: string;
  endingDate: string;
  description: string;
}

const GoalsModal: React.FC<GoalsModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const { addGoals} = useGoals();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await addGoals({
        amount: Number(data.amount),
        description: data.description,
        startingDate: new Date(data.startingDate).toISOString(),
        endingDate: new Date(data.endingDate).toISOString()
      })
      reset();
      onClose();
    } catch (error) {
      console.error('Failed to add transaction:', error);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.95, y: 20 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="bg-black border border-zinc-800 rounded-xl shadow-xl w-full max-w-md mx-4 md:mx-0 md:max-w-lg overflow-hidden"
          >
            <div className="flex justify-between items-center p-6 border-b border-zinc-800">
              <h2 className="text-2xl font-semibold text-cyan-100">{t('addGoal')}</h2>
              <button 
                onClick={onClose}
                className="text-zinc-500 hover:text-cyan-100 transition-colors"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-zinc-400 mb-1">{t('amount')}</label>
                <input
                  type="number"
                  {...register('amount', { required: true })}
                  className="w-full bg-zinc-900 text-cyan-100 p-3 rounded-md border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-zinc-400 mb-1">{t('description')}</label>
                <input
                  type="text"
                  {...register('description', { required: true })}
                  className="w-full bg-zinc-900 text-cyan-100 p-3 rounded-md border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-zinc-400 mb-1">{t('startingDate')}</label>
                <input
                  type="date"
                  {...register('startingDate', { required: true })}
                  className="w-full bg-zinc-900 text-cyan-100 p-3 rounded-md border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-zinc-400 mb-1">{t('endingDate')}</label>
                <input
                  type="date"
                  {...register('endingDate', { required: true })}
                  className="w-full bg-zinc-900 text-cyan-100 p-3 rounded-md border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-all"
                />
              </div>
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 rounded-md bg-zinc-800 text-zinc-300 hover:bg-zinc-700 transition-colors"
                >
                  {t('cancel')}
                </button>
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-md text-black font-medium bg-green-500 hover:bg-green-600 transition-colors`}
                >
                  {t('addGoal')}
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GoalsModal;
import React, { useMemo } from 'react';
import { format, differenceInDays } from 'date-fns';
import { useTransactions } from '../TransactionContext';
import { Trash2 } from 'lucide-react';
import { useGoals } from '../GoalsContext';
import { useTranslation } from 'react-i18next';

interface Goal {
  id: string;
  description: string;
  amount: number;
  startingDate: string;
  endingDate: string;
}

interface GoalCardProps {
  goal: Goal;
}

const GoalCard: React.FC<GoalCardProps> = ({ goal }) => {
    const { t } = useTranslation();
    const { transactions } = useTransactions();
    const { deleteGoal } = useGoals();
    const transactionsInPeriod = useMemo(()=>{
    return [...transactions].filter((transaction)=>{
        if(transaction.date >= goal.startingDate && transaction.date <= goal.endingDate){
            return transaction;
        }
    })
    }, [transactions])
    const expensesTransactions = useMemo(() => {
        return transactionsInPeriod.filter((transaction) => transaction.type === 'Expense');
      }, [transactionsInPeriod]);
    
      const incomeTransactions = useMemo(() => {
        return transactionsInPeriod.filter((transaction) => transaction.type === 'Income');
      }, [transactionsInPeriod]);

      const totalSaved = transactionsInPeriod.reduce((acc, transaction) => 
        transaction.type === 'Income' ? acc + transaction.amount : acc - transaction.amount, 0);

      const daysLeft = differenceInDays(new Date(goal.endingDate), new Date());
      const totalDays = differenceInDays(new Date(goal.endingDate), new Date(goal.startingDate));
      const dailyAmount = (goal.amount - totalSaved) / totalDays;
      const elapsedDays = totalDays - daysLeft;
      const estimatedProgress = (totalSaved / goal.amount) * 100;

  return (
    <div className="bg-black border border-zinc-600 rounded-lg p-4 m-1 text-white shadow-lg w-64 flex-shrink-0 relative">
      <button 
        onClick={() => deleteGoal(goal.id)}
        className="absolute top-1 right-1 text-gray-600 hover:text-red-500 focus:text-red-500 transition-colors duration-300"
        aria-label={t('deleteGoal')}
      >
        <Trash2 size={18} />
      </button>
      <div className="flex justify-between items-start mb-4 mt-2">
        <div>
          <h2 className="text-xl font-bold">{goal.description}</h2>
          <p className="text-sm text-gray-400">
          {t('saveDaily', { amount: dailyAmount.toFixed(2), days: totalDays })}
          </p>
          <p className="text-sm text-gray-400 mt-1">
          {t('daysLeft', { days: daysLeft })}
          </p>
        </div>
        <div className="text-right">
          <p className="text-2xl font-bold">{t('currencySymbol')}{totalSaved}</p>
          <p className="text-sm text-gray-400">/{t('currencySymbol')}{goal.amount}</p>
        </div>
      </div>
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-gray-400">
              {estimatedProgress.toFixed(0)}%
            </span>
          </div>
        </div>
        <div className="flex h-2 mb-4 overflow-hidden text-xs bg-gray-700 rounded">
          <div 
            style={{ width: `${estimatedProgress > 0 ? estimatedProgress.toFixed(0) : 0}%` }}
            className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
          ></div>
        </div>
      </div>
      <div className="flex justify-between text-xs text-gray-400">
        <div>{format(new Date(goal.startingDate), 'MMM d')}</div>
        <div>{format(new Date(goal.endingDate), 'MMM d')}</div>
      </div>
    </div>
  );
};

export default GoalCard;
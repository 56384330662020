import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';
import { useClickAway } from 'react-use';

const languages = [
  { code: 'en', name: 'English', shortName: 'EN' },
  { code: 'pt-BR', name: 'Português', shortName: 'PT' },
  { code: 'es', name: 'Español', shortName: 'ES' },
];

interface LanguageSelectorProps {
  expanded: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ expanded }) => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  });

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  return (
    <div ref={ref} className="relative w-full">
      <button
        type="button"
        onClick={toggleDropdown}
        className={`flex items-center ${expanded ? 'justify-start' : 'justify-center'} w-full shadow-sm px-4 py-2 bg-black border-zinc-600 text-sm font-medium text-gray-200 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-zinc-800 focus:ring-cyan-500 h-16`}
        aria-label="Select language"
      >
        <Globe className={`h-6 w-6 ${expanded ? 'mr-3' : ''}`} />
        {expanded && (
          <>
            <span className="flex-grow">{currentLanguage.name}</span>
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </>
        )}
      </button>
      {dropdownOpen && (
        <div className={`absolute ${expanded ? 'top-full left-0 w-full' : 'top-0 left-full ml-1'} bg-black border-zinc-600 rounded-md shadow-lg z-10`}>
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className={`${
                i18n.language === lang.code ? 'bg-zinc-700 text-cyan-100' : 'text-gray-200'
              } block w-full text-left px-4 py-2 text-sm hover:bg-zinc-700 hover:text-cyan-100`}
            >
              {expanded ? lang.name : lang.shortName}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
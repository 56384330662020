import React, { useMemo } from 'react';
import { Card, Title, AreaChart } from "@tremor/react";
import { useTransactions } from '../TransactionContext';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const AreaChartComponent: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { transactions } = useTransactions();

  const chartData = useMemo(() => {
    // Sort transactions by date
    const sortedTransactions = [...transactions].sort((a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    // Group transactions by month and calculate total saved
    const monthlyData: { [key: string]: number } = {};
    let cumulativeSavings = 0;

    const incomeTypes = ['Income', 'Renda', 'Ingreso']; // Add all possible translations for 'Income'

    sortedTransactions.forEach(transaction => {
      const monthKey = format(new Date(transaction.date), 'MMM yy');
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = cumulativeSavings;
      }
      
      if (incomeTypes.includes(transaction.type)) {
        cumulativeSavings += transaction.amount;
      } else {
        cumulativeSavings -= transaction.amount;
      }
      
      monthlyData[monthKey] = cumulativeSavings;
    });

    // Convert to chart data format
    return Object.entries(monthlyData).map(([date, totalSaved]) => ({
      date,
      [t('totalSaved')]: totalSaved
    }));
  }, [transactions, t]);

  // Ensure we have at least two data points for the chart
  const displayData = useMemo(() => {
    if (chartData.length < 2) {
      const currentMonth = format(new Date(), 'MMM yy');
      const nextMonth = format(new Date(new Date().setMonth(new Date().getMonth() + 1)), 'MMM yy');
      return [
        { date: currentMonth, [t('totalSaved')]: 0 },
        { date: nextMonth, [t('totalSaved')]: 0 },
        ...chartData
      ];
    }
    return chartData;
  }, [chartData, t]);

  return (
    <Card className="mt-4 bg-black text-cyan-100 p-4">
      <Title className="text-cyan-100">{t('totalSavedCumulative')}</Title>
      <AreaChart
        className="h-72 mt-4"
        data={displayData}
        index="date"
        categories={[t('totalSaved')]}
        colors={["green"]}
        valueFormatter={(number) => `${t('currencySymbol')}${Intl.NumberFormat(i18n.language).format(number)}`}
        showLegend={false}
        showYAxis={false}
        showGridLines={false}
        curveType="monotone"
        startEndOnly={true}
      />
    </Card>
  );
};

export default AreaChartComponent;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { loginWithGoogle } from '../services/api';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    loginWithGoogle();
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Background gradients and patterns */}
      <div className="absolute inset-0 bg-gradient-to-b from-blue-950 via-[#05092c] to-black"></div>
      <div className="absolute inset-0 bg-[radial-gradient(100%_100%_at_50%_0%,transparent_0%,rgba(0,0,0,0.7)_100%)]"></div>
      <div className="absolute inset-0 bg-[radial-gradient(80%_50%_at_50%_-30%,rgba(115,224,169,0.15),rgba(255,255,255,0))]"></div>
      <div className="absolute inset-0 opacity-10">
        <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="hexagons" width="50" height="50" patternUnits="userSpaceOnUse" patternTransform="scale(0.5)">
              <path d="M25 0 L50 14.4 L50 28.9 L25 43.4 L0 28.9 L0 14.4 Z" fill="none" stroke="white" stroke-opacity="0.3"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#hexagons)" />
        </svg>
      </div>

      {/* Content */}
      <div className="relative z-10 flex flex-col min-h-screen px-4 py-16 sm:px-6 lg:px-8">
        {/* Header with Dashboard button */}
        <header className="absolute top-0 right-0 m-4">
          <button 
            onClick={handleGoogleLogin}
            className="px-6 py-2 text-sm font-medium text-white bg-[#111111] rounded-xl hover:bg-opacity-75 transition duration-300 flex items-center"
          >
            {t('dashboard')}
          </button>
        </header>

        {/* Main content area */}
        <div className="flex flex-col md:flex-row items-center justify-between flex-grow">
          {/* Text content */}
          <div className="max-w-xl mb-8 md:mb-0 text-center md:text-left">
            <h1 className="mb-4 text-4xl font-bold text-white sm:text-5xl md:text-6xl">
            {t('keepingTrack')}
              <span className="bg-gradient-to-b from-[#4169E1] to-[#00008B] inline-block text-transparent bg-clip-text">
                {t('easier')}
              </span>
            </h1>
            <p className="mb-6 text-xl text-gray-300">
            {t('trackFinancesRealTime')}
            </p>
          </div>

          {/* Image placeholder */}
          <div className="w-full max-w-md">
            <div className="aspect-w-16 aspect-h-9 rounded-lg">
              {/* Replace this div with an actual image when you have one */}
              <div className="flex items-center justify-center text-gray-500">
                <img src="/phoneRender.png" alt="Preview"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
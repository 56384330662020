import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData } from '../services/api';
import { Sidebar } from './Sidebar';
import { GiStairsGoal } from "react-icons/gi";
import Selector from './Selector';
import TransactionSummaryCards from './TransactionSummaryCards';
import SettingsPage from './SettingsPage';
import { motion } from "framer-motion";
import TransactionsPage from './TransactionsPage';
import GoalsModal from './GoalsModal';
import { useGoals } from '../GoalsContext';
import { format, parseISO } from 'date-fns';
import GoalCard from './GoalCard';
import { useTranslation } from 'react-i18next';

interface User {
  email: string;
  username: string;
  preferredCurrency: string;
}

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [selectedItem, setSelectedItem] = useState<string>('home');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const { goals } = useGoals();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData()
      .then(userData => {
        setUser(userData);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        navigate('/login');
      });
  }, [navigate]);

  const handleSelectItem = (id: string) => {
    setSelectedItem(id);
  };
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case 'home':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <div>
              <h2 className="text-2xl font-bold mb-10">{t('welcome', { username: user?.username })}</h2>
              <div className="flex flex-wrap items-start mb-4">
                {goals.map((goal) => (
                  <GoalCard key={goal.id} goal={goal} />
                ))}
                <button 
                  className="m-1 flex-grow flex flex-col items-center justify-center bg-inherit border border-zinc-600 rounded-lg shadow"
                  onClick={() => handleOpenModal()}
                  style={{ height: '12.5rem' }}
                >
                  <GiStairsGoal className="text-2xl opacity-50 mb-2" />
                  <span className="text-sm opacity-50">{t('addGoal')}</span>
                </button>
              </div>
              <GoalsModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
                <div className="mb-8">
                  <TransactionSummaryCards />
                </div>
                <div className="items-center justify-center">
                  <Selector />
                </div>
              </div>
            </div>
          </motion.div>
        );
      case 'transactions':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <TransactionsPage/>
          </motion.div>
        )
      case 'settings':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <SettingsPage/>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-black text-cyan-100">
      {user && goals ? (
        <div className="flex">
          <Sidebar onSelectItem={handleSelectItem} selectedItem={selectedItem} />
          <main className="flex-grow p-8 ml-16 md:ml-64">
            <header className="mb-8">
            </header>
            {renderContent()}
          </main>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <p className="text-xl">`{t('loadingUserData')}</p>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
import React, { useState, useEffect, useMemo } from 'react';
import { format, addMonths, subMonths, parseISO, isSameMonth } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { Card, DonutChart, List, ListItem, Title } from '@tremor/react';
import api from '../services/api';
import { useTranslation } from 'react-i18next';

interface Transaction {
  id: string;
  date: string;
  description: string;
  amount: number;
  category: string;
}

const TransactionsPage: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [allTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const fetchAllTransactions = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get('/api/transaction');
      if (response.status === 200) {
        const transactions = response.data;
        console.log(transactions); // For debugging, remove in production
        setAllTransactions(transactions.map((transaction: any) => ({
          id: transaction.id.toString(),
          date: transaction.date,
          description: transaction.description,
          amount: transaction.type === 'Expense' ? -Math.abs(transaction.amount) : Math.abs(transaction.amount),
          category: transaction.category
        })));
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setError('Failed to fetch transactions. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const handleMonthChange = (direction: 'prev' | 'next') => {
    setSelectedMonth(prev => 
      direction === 'prev' ? subMonths(prev, 1) : addMonths(prev, 1)
    );
  };

  const filteredTransactions = useMemo(() => {
    return allTransactions.filter(transaction => 
      isSameMonth(parseISO(transaction.date), selectedMonth)
    );
  }, [allTransactions, selectedMonth]);

  const categoryData = useMemo(() => {
    return filteredTransactions.reduce((acc, transaction) => {
      if (transaction.amount < 0) {
        const category = transaction.category;
        const amount = Math.abs(transaction.amount);
        acc[category] = (acc[category] || 0) + amount;
      }
      return acc;
    }, {} as Record<string, number>);
  }, [filteredTransactions]);

  const chartData = useMemo(() => {
    return Object.entries(categoryData).map(([name, amount]) => ({ name, amount }));
  }, [categoryData]);

  const valueFormatter = (number: number) => `$${number.toFixed(2)}`;

  if (isLoading) {
    return <div className="text-cyan-100">{t('loadingTransactions')}</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Card className="bg-black border !border-zinc-600 !border-0 p-4">
        <Title className="text-cyan-100 mb-4">{t('transactions')}</Title>
        <div className="flex items-center justify-between mb-4 bg-zinc-800 p-2 rounded-lg">
          <ChevronLeftIcon 
            className="cursor-pointer text-cyan-100" 
            onClick={() => handleMonthChange('prev')}
          />
          <span className="text-lg font-semibold text-cyan-100">
            {format(selectedMonth, 'MMMM yyyy')}
          </span>
          <ChevronRightIcon 
            className="cursor-pointer text-cyan-100" 
            onClick={() => handleMonthChange('next')}
          />
        </div>

        <div className="space-y-2 overflow-y-auto">
          {filteredTransactions.map(transaction => (
            <div key={transaction.id} className="flex justify-between items-center bg-zinc-900 p-2 rounded">
              <div>
                <div className="font-semibold text-cyan-100">{transaction.description}</div>
                <div className="text-sm text-gray-400">{format(parseISO(transaction.date), 'MMM d, yyyy')}</div>
              </div>
              <div className={`font-bold ${transaction.amount >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {transaction.amount >= 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      </Card>

      <Card className="bg-black border !border-zinc-600 !border-0 p-4">
        <Title className="text-cyan-100 mb-4">{t('expensesByCategory')}</Title>
        {chartData.length > 0 ? (
          <>
            <DonutChart
              className="mt-6"
              data={chartData}
              category="amount"
              index="name"
              valueFormatter={valueFormatter}
              colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
            />
            <List className="mt-6">
              {chartData.map((item) => (
                <ListItem key={item.name} className="text-cyan-100">
                  <span>{item.name}</span>
                  <span>{valueFormatter(item.amount)}</span>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <p className="text-cyan-100 text-center mt-6">{t('noExpensesThisPeriod')}</p>
        )}
      </Card>
    </div>
  );
};

export default TransactionsPage;
import React from 'react';
import ExpensesChart from './expensesChart';
import AreaChartComponent from './AreaChart';
import { useTransactions } from '../TransactionContext';
import { useTranslation } from 'react-i18next';

interface TransactionCardProps {
  title: string;
  amount: number;
  transactions: number;
}


const TransactionSummaryCards: React.FC = () => {
  const { transactions } = useTransactions();
  const { t } = useTranslation();

  const TransactionCard: React.FC<TransactionCardProps> = ({ title, amount, transactions }) => (
    <div className="bg-black border border-zinc-600 rounded-lg p-4 flex flex-col">
      <h3 className="text-cyan-100 text-lg font-semibold mb-2">{title}</h3>
      <span className="text-cyan-400 text-2xl font-bold">{t('currencySymbol')}{amount.toFixed(2)}</span>
      {title === t('upcoming') && (
        <span className="text-gray-400 text-sm">{t('transactionsCount',{ count: transactions})}</span>
      )}
    </div>
  );
  const totalSaved = transactions.reduce((acc, transaction) => 
    transaction.type === 'Income' ? acc + transaction.amount : acc - transaction.amount, 0);

  const upcomingTransactions = transactions.filter(t => new Date(t.date) > new Date());
  const upcomingTransactionValue = upcomingTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-4xl mx-auto">
      <div className="space-y-4">
        <TransactionCard title={t('saved')} amount={totalSaved} transactions={0} />
        <div>
          <AreaChartComponent />
        </div>
      </div>
      <div>
        <TransactionCard title={t('upcoming')} amount={upcomingTransactionValue} transactions={upcomingTransactions.length} />
        <div className='mt-4'>
          <ExpensesChart />
        </div>
      </div>
    </div>
  );
};

export default TransactionSummaryCards;
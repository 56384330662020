import React, { useEffect, useState } from 'react';
import { Title, Button, Select, SelectItem } from '@tremor/react';
import { ChevronDownIcon, Currency } from 'lucide-react';
import api from '../services/api';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

interface Currency {
    value: string;
    name: string;
  }

const currencies = [
    { value: 'AED', name: 'United Arab Emirates Dirham' },
    { value: 'AFN', name: 'Afghan Afghani' },
    { value: 'ALL', name: 'Albanian Lek' },
    { value: 'AMD', name: 'Armenian Dram' },
    { value: 'ANG', name: 'Netherlands Antillean Guilder' },
    { value: 'AOA', name: 'Angolan Kwanza' },
    { value: 'ARS', name: 'Argentine Peso' },
    { value: 'AUD', name: 'Australian Dollar' },
    { value: 'AWG', name: 'Aruban Florin' },
    { value: 'AZN', name: 'Azerbaijani Manat' },
    { value: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark' },
    { value: 'BBD', name: 'Barbadian Dollar' },
    { value: 'BDT', name: 'Bangladeshi Taka' },
    { value: 'BGN', name: 'Bulgarian Lev' },
    { value: 'BHD', name: 'Bahraini Dinar' },
    { value: 'BIF', name: 'Burundian Franc' },
    { value: 'BMD', name: 'Bermudan Dollar' },
    { value: 'BND', name: 'Brunei Dollar' },
    { value: 'BOB', name: 'Bolivian Boliviano' },
    { value: 'BRL', name: 'Brazilian Real' },
    { value: 'BSD', name: 'Bahamian Dollar' },
    { value: 'BTN', name: 'Bhutanese Ngultrum' },
    { value: 'BWP', name: 'Botswanan Pula' },
    { value: 'BYN', name: 'Belarusian Ruble' },
    { value: 'BZD', name: 'Belize Dollar' },
    { value: 'CAD', name: 'Canadian Dollar' },
    { value: 'CDF', name: 'Congolese Franc' },
    { value: 'CHF', name: 'Swiss Franc' },
    { value: 'CLP', name: 'Chilean Peso' },
    { value: 'CNY', name: 'Chinese Yuan' },
    { value: 'COP', name: 'Colombian Peso' },
    { value: 'CRC', name: 'Costa Rican Colón' },
    { value: 'CUC', name: 'Cuban Convertible Peso' },
    { value: 'CUP', name: 'Cuban Peso' },
    { value: 'CVE', name: 'Cape Verdean Escudo' },
    { value: 'CZK', name: 'Czech Republic Koruna' },
    { value: 'DJF', name: 'Djiboutian Franc' },
    { value: 'DKK', name: 'Danish Krone' },
    { value: 'DOP', name: 'Dominican Peso' },
    { value: 'DZD', name: 'Algerian Dinar' },
    { value: 'EGP', name: 'Egyptian Pound' },
    { value: 'ERN', name: 'Eritrean Nakfa' },
    { value: 'ETB', name: 'Ethiopian Birr' },
    { value: 'EUR', name: 'Euro' },
    { value: 'FJD', name: 'Fijian Dollar' },
    { value: 'FKP', name: 'Falkland Islands Pound' },
    { value: 'GBP', name: 'British Pound Sterling' },
    { value: 'GEL', name: 'Georgian Lari' },
    { value: 'GGP', name: 'Guernsey Pound' },
    { value: 'GHS', name: 'Ghanaian Cedi' },
    { value: 'GIP', name: 'Gibraltar Pound' },
    { value: 'GMD', name: 'Gambian Dalasi' },
    { value: 'GNF', name: 'Guinean Franc' },
    { value: 'GTQ', name: 'Guatemalan Quetzal' },
    { value: 'GYD', name: 'Guyanaese Dollar' },
    { value: 'HKD', name: 'Hong Kong Dollar' },
    { value: 'HNL', name: 'Honduran Lempira' },
    { value: 'HRK', name: 'Croatian Kuna' },
    { value: 'HTG', name: 'Haitian Gourde' },
    { value: 'HUF', name: 'Hungarian Forint' },
    { value: 'IDR', name: 'Indonesian Rupiah' },
    { value: 'ILS', name: 'Israeli New Sheqel' },
    { value: 'IMP', name: 'Manx pound' },
    { value: 'INR', name: 'Indian Rupee' },
    { value: 'IQD', name: 'Iraqi Dinar' },
    { value: 'IRR', name: 'Iranian Rial' },
    { value: 'ISK', name: 'Icelandic Króna' },
    { value: 'JEP', name: 'Jersey Pound' },
    { value: 'JMD', name: 'Jamaican Dollar' },
    { value: 'JOD', name: 'Jordanian Dinar' },
    { value: 'JPY', name: 'Japanese Yen' },
    { value: 'KES', name: 'Kenyan Shilling' },
    { value: 'KGS', name: 'Kyrgystani Som' },
    { value: 'KHR', name: 'Cambodian Riel' },
    { value: 'KMF', name: 'Comorian Franc' },
    { value: 'KPW', name: 'North Korean Won' },
    { value: 'KRW', name: 'South Korean Won' },
    { value: 'KWD', name: 'Kuwaiti Dinar' },
    { value: 'KYD', name: 'Cayman Islands Dollar' },
    { value: 'KZT', name: 'Kazakhstani Tenge' },
    { value: 'LAK', name: 'Laotian Kip' },
    { value: 'LBP', name: 'Lebanese Pound' },
    { value: 'LKR', name: 'Sri Lankan Rupee' },
    { value: 'LRD', name: 'Liberian Dollar' },
    { value: 'LSL', name: 'Lesotho Loti' },
    { value: 'LYD', name: 'Libyan Dinar' },
    { value: 'MAD', name: 'Moroccan Dirham' },
    { value: 'MDL', name: 'Moldovan Leu' },
    { value: 'MGA', name: 'Malagasy Ariary' },
    { value: 'MKD', name: 'Macedonian Denar' },
    { value: 'MMK', name: 'Myanma Kyat' },
    { value: 'MNT', name: 'Mongolian Tugrik' },
    { value: 'MOP', name: 'Macanese Pataca' },
    { value: 'MRU', name: 'Mauritanian Ouguiya' },
    { value: 'MUR', name: 'Mauritian Rupee' },
    { value: 'MVR', name: 'Maldivian Rufiyaa' },
    { value: 'MWK', name: 'Malawian Kwacha' },
    { value: 'MXN', name: 'Mexican Peso' },
    { value: 'MYR', name: 'Malaysian Ringgit' },
    { value: 'MZN', name: 'Mozambican Metical' },
    { value: 'NAD', name: 'Namibian Dollar' },
    { value: 'NGN', name: 'Nigerian Naira' },
    { value: 'NIO', name: 'Nicaraguan Córdoba' },
    { value: 'NOK', name: 'Norwegian Krone' },
    { value: 'NPR', name: 'Nepalese Rupee' },
    { value: 'NZD', name: 'New Zealand Dollar' },
    { value: 'OMR', name: 'Omani Rial' },
    { value: 'PAB', name: 'Panamanian Balboa' },
    { value: 'PEN', name: 'Peruvian Nuevo Sol' },
    { value: 'PGK', name: 'Papua New Guinean Kina' },
    { value: 'PHP', name: 'Philippine Peso' },
    { value: 'PKR', name: 'Pakistani Rupee' },
    { value: 'PLN', name: 'Polish Zloty' },
    { value: 'PYG', name: 'Paraguayan Guarani' },
    { value: 'QAR', name: 'Qatari Rial' },
    { value: 'RON', name: 'Romanian Leu' },
    { value: 'RSD', name: 'Serbian Dinar' },
    { value: 'RUB', name: 'Russian Ruble' },
    { value: 'RWF', name: 'Rwandan Franc' },
    { value: 'SAR', name: 'Saudi Riyal' },
    { value: 'SBD', name: 'Solomon Islands Dollar' },
    { value: 'SCR', name: 'Seychellois Rupee' },
    { value: 'SDG', name: 'Sudanese Pound' },
    { value: 'SEK', name: 'Swedish Krona' },
    { value: 'SGD', name: 'Singapore Dollar' },
    { value: 'SHP', name: 'Saint Helena Pound' },
    { value: 'SLL', name: 'Sierra Leonean Leone' },
    { value: 'SOS', name: 'Somali Shilling' },
    { value: 'SRD', name: 'Surinamese Dollar' },
    { value: 'SSP', name: 'South Sudanese Pound' },
    { value: 'STN', name: 'São Tomé and Príncipe Dobra' },
    { value: 'SVC', name: 'Salvadoran Colón' },
    { value: 'SYP', name: 'Syrian Pound' },
    { value: 'SZL', name: 'Swazi Lilangeni' },
    { value: 'THB', name: 'Thai Baht' },
    { value: 'TJS', name: 'Tajikistani Somoni' },
    { value: 'TMT', name: 'Turkmenistani Manat' },
    { value: 'TND', name: 'Tunisian Dinar' },
    { value: 'TOP', name: 'Tongan Pa\'anga' },
    { value: 'TRY', name: 'Turkish Lira' },
    { value: 'TTD', name: 'Trinidad and Tobago Dollar' },
    { value: 'TWD', name: 'New Taiwan Dollar' },
    { value: 'TZS', name: 'Tanzanian Shilling' },
    { value: 'UAH', name: 'Ukrainian Hryvnia' },
    { value: 'UGX', name: 'Ugandan Shilling' },
    { value: 'USD', name: 'United States Dollar' },
    { value: 'UYU', name: 'Uruguayan Peso' },
    { value: 'UZS', name: 'Uzbekistan Som' },
    { value: 'VES', name: 'Venezuelan Bolívar Soberano' },
    { value: 'VND', name: 'Vietnamese Dong' },
    { value: 'VUV', name: 'Vanuatu Vatu' },
    { value: 'WST', name: 'Samoan Tala' },
    { value: 'XAF', name: 'CFA Franc BEAC' },
    { value: 'XCD', name: 'East Caribbean Dollar' },
    { value: 'XDR', name: 'Special Drawing Rights' },
    { value: 'XOF', name: 'CFA Franc BCEAO' },
    { value: 'XPF', name: 'CFP Franc' },
    { value: 'YER', name: 'Yemeni Rial' },
    { value: 'ZAR', name: 'South African Rand' },
    { value: 'ZMW', name: 'Zambian Kwacha' },
    { value: 'ZWL', name: 'Zimbabwean Dollar' }
  ];
  const SettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const [preferredCurrency, setPreferredCurrency] = useState<string>('USD');
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Fetch user's current preferred currency when component mounts
      const fetchUserCurrency = async () => {
        try {
          const response = await api.get('/api/user');
          setPreferredCurrency(response.data.preferredCurrency);
        } catch (error) {
          console.error('Error fetching user data:', error);
          // If unauthorized, redirect to login
          if (isAxiosError(error) && error.response?.status === 401) {
            navigate('/login');
          }
        }
      };
  
      fetchUserCurrency();
    }, [navigate]);
  
    const handleCurrencyChange = async (value: string) => {
      setIsUpdating(true);
      try {
        const response = await api.put('/api/user/currency', { currency: value });
        if (response.status === 200) {
          setPreferredCurrency(value);
          console.log('Currency updated successfully');
        }
      } catch (error) {
        console.error('Failed to update currency', error);
      } finally {
        setIsUpdating(false);
      }
    };
  
    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
          const response = await api.post('/api/auth/logout');
          if (response.status === 200) {
            console.log('Logout Success');
            navigate('/login');
          } else {
            console.error('Logout Failed: Unexpected status code', response.status);
          }
        } catch (error) {
          console.error('Error during logout', error);
          // Handle error (e.g., show a notification to the user)
        } finally {
          setIsLoggingOut(false);
        }
      };

  return (
    <div className="min-h-screen bg-black text-cyan-100 p-8">
      <Title className="text-3xl font-bold mb-8">{t('settings')}</Title>
      
      <div className="max-w-2xl mx-auto space-y-12">
        <div className='hidden'>
          <h2 className="text-xl font-semibold mb-4">{t('preferredCurrency')}</h2>
          <Select
            value={preferredCurrency}
            onValueChange={handleCurrencyChange}
            disabled={isUpdating}
            className="w-full bg-zinc-800 border-zinc-600 text-cyan-100"
            icon={ChevronDownIcon}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.value} value={currency.value}>
                {currency.name}
              </SelectItem>
            ))}
          </Select>
          {isUpdating && <p className="mt-2 text-sm text-cyan-300">{t('updatingCurrency')}</p>}
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">{t('account')}</h2>
          <Button
            onClick={handleLogout}
            disabled={isLoggingOut}
            className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoggingOut ? t('loggingOut') : t('logout')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
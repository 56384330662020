import React, { useState, useMemo } from 'react';
import TransactionModal from './TransactionModal';
import { format, parseISO } from 'date-fns';
import { useTransactions } from '../TransactionContext';
import { ArrowUpRight, ArrowDownRight, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface Transaction {
  id: string;
  date: string;
  description: string;
  amount: number;
  category: string;
  type: 'Expense' | 'Income';
}

const Selector: React.FC = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('All');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'Expense' | 'Income'>('Expense');
  const { transactions, deleteTransaction } = useTransactions();

  const sortedTransactions = useMemo(() => {
    return [...transactions].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }, [transactions]);

  const expensesTransactions = useMemo(() => {
    return sortedTransactions.filter((transaction) => transaction.type === 'Expense');
  }, [sortedTransactions]);

  const incomeTransactions = useMemo(() => {
    return sortedTransactions.filter((transaction) => transaction.type === 'Income');
  }, [sortedTransactions]);


  const handleSelect = (option: string) => {
    setSelected(option);
  };

  const handleOpenModal = (type: 'Expense' | 'Income') => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full h-auto bg-black border border-zinc-600 rounded-lg p-5">
      <div className="flex bg-black border-zinc-800 rounded-full p-1 w-full">
        <button
          className={`flex-1 py-2 px-2 rounded-full transition-colors duration-300 text-xs sm:text-sm ${
            selected === 'All' ? 'bg-gray-700 text-cyan-100' : 'text-gray-400 hover:bg-gray-800'
          }`}
          onClick={() => handleSelect('All')}
        >
          {t('recent')}
        </button>
        <button
          className={`flex-1 py-2 px-2 mx-1 rounded-full transition-colors duration-300 text-xs sm:text-sm ${
            selected === 'Expense' ? 'bg-gray-700 text-cyan-100' : 'text-gray-400 hover:bg-gray-800'
          }`}
          onClick={() => handleSelect('Expense')}
        >
          {t('expenses')}
        </button>
        <button
          className={`flex-1 py-2 px-2 rounded-full transition-colors duration-300 text-xs sm:text-sm ${
            selected === 'Income' ? 'bg-gray-700 text-cyan-100' : 'text-gray-400 hover:bg-gray-800'
          }`}
          onClick={() => handleSelect('Income')}
        >
          {t('income')}
        </button>
      </div>
      {selected === 'All' && (
        <div className="pt-2 space-y-2 overflow-y-auto">
          {sortedTransactions.map(transaction => (
            <div key={transaction.id} className="flex justify-between items-center bg-black border border-zinc-600 p-3 rounded-lg hover:bg-zinc-700 transition-colors duration-300">
              <div className="flex items-center space-x-3 flex-1 min-w-0">
                <div className={`p-2 rounded-full flex-shrink-0 ${transaction.type === 'Income' ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
                  {transaction.type === 'Income' 
                    ? <ArrowUpRight className="text-green-500" size={20} />
                    : <ArrowDownRight className="text-red-500" size={20} />
                  }
                </div>
                <div className="flex-1 min-w-0">
                  <div className="font-semibold text-cyan-100 truncate">{transaction.description}</div>
                  <div className="text-sm text-gray-400">{format(parseISO(transaction.date), 'MMM d, yyyy')}</div>
                </div>
              </div>
              <div className="flex items-center space-x-4 flex-shrink-0">
                <div className={`font-bold text-lg ${transaction.type === 'Income' ? 'text-green-500' : 'text-red-500'}`}>
                  {transaction.type === 'Income' ? '+' : '-'}{t('currencySymbol')}{Math.abs(transaction.amount).toFixed(2)}
                </div>
                <button 
                  onClick={() => deleteTransaction(transaction.id)} 
                  className="text-gray-600 hover:text-red-500 focus:text-red-500 transition-colors duration-300"
                  aria-label={t('deleteTransaction')}
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {selected === 'Income' && (
        <>
          <button
            className="w-full mt-4 py-2 px-4 bg-black text-cyan-100 rounded-full border border-green-600 text-xs sm:text-sm"
            onClick={() => handleOpenModal('Income')}
          >
            {t('addIncome')}
          </button>
          <div className="pt-2 space-y-2 overflow-y-auto">
            {incomeTransactions.map(transaction => (
            <div key={transaction.id} className="flex justify-between items-center bg-black border border-zinc-600 p-3 rounded-lg hover:bg-zinc-700 transition-colors duration-300">
            <div className="flex items-center space-x-3 flex-1 min-w-0">
              <div className={`p-2 rounded-full flex-shrink-0 ${transaction.type === 'Income' ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
                {transaction.type === 'Income' 
                  ? <ArrowUpRight className="text-green-500" size={20} />
                  : <ArrowDownRight className="text-red-500" size={20} />
                }
              </div>
              <div className="flex-1 min-w-0">
                <div className="font-semibold text-cyan-100 truncate">{transaction.description}</div>
                <div className="text-sm text-gray-400">{format(parseISO(transaction.date), 'MMM d, yyyy')}</div>
              </div>
            </div>
            <div className="flex items-center space-x-4 flex-shrink-0">
              <div className={`font-bold text-lg ${transaction.type === 'Income' ? 'text-green-500' : 'text-red-500'}`}>
                {transaction.type === 'Income' ? '+' : '-'}{t('currencySymbol')}{Math.abs(transaction.amount).toFixed(2)}
              </div>
              <button 
                onClick={() => deleteTransaction(transaction.id)} 
                className="text-gray-600 hover:text-red-500 focus:text-red-500 transition-colors duration-300"
                aria-label={t('deleteTransaction')}
              >
                <Trash2 size={18} />
              </button>
            </div>
          </div>
            ))}
          </div>
          <TransactionModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            type={modalType}
          />
        </>
      )}
      {selected === 'Expense' && (
        <>
          <button
            className="w-full mt-4 py-2 px-4 bg-black text-cyan-100 rounded-full border border-red-500 text-xs sm:text-sm"
            onClick={() => handleOpenModal('Expense')}
          >
            {t('addExpense')}
          </button>
          <div className="pt-2 space-y-2 overflow-y-auto">
            {expensesTransactions.map(transaction => (
            <div key={transaction.id} className="flex justify-between items-center bg-black border border-zinc-600 p-3 rounded-lg hover:bg-zinc-700 transition-colors duration-300">
            <div className="flex items-center space-x-3 flex-1 min-w-0">
              <div className={`p-2 rounded-full flex-shrink-0 ${transaction.type === 'Income' ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
                {transaction.type === 'Income' 
                  ? <ArrowUpRight className="text-green-500" size={20} />
                  : <ArrowDownRight className="text-red-500" size={20} />
                }
              </div>
              <div className="flex-1 min-w-0">
                <div className="font-semibold text-cyan-100 truncate">{transaction.description}</div>
                <div className="text-sm text-gray-400">{format(parseISO(transaction.date), 'MMM d, yyyy')}</div>
              </div>
            </div>
            <div className="flex items-center space-x-4 flex-shrink-0">
              <div className={`font-bold text-lg ${transaction.type === 'Income' ? 'text-green-500' : 'text-red-500'}`}>
                {transaction.type === 'Income' ? '+' : '-'}{t('currencySymbol')}{Math.abs(transaction.amount).toFixed(2)}
              </div>
              <button 
                onClick={() => deleteTransaction(transaction.id)} 
                className="text-gray-600 hover:text-red-500 focus:text-red-500 transition-colors duration-300"
                aria-label={t('deleteTransaction')}
              >
                <Trash2 size={18} />
              </button>
            </div>
          </div>
            ))}
          </div>
          <TransactionModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            type={modalType}
          />
        </>
      )}
    </div>
  );
};

export default Selector;
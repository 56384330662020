import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import AuthCallback from './components/AuthCallback';
import { TransactionsProvider } from './TransactionContext';
import { GoalsProvider } from './GoalsContext';

const App: React.FC = () => {
  return (
    <TransactionsProvider>
      <GoalsProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </Router>
      </GoalsProvider>
    </TransactionsProvider>
  );
}

export default App;
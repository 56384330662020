import React, { useRef, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useClickAway } from 'react-use'
import { AiOutlineRollback } from 'react-icons/ai'
import { BiHomeSmile } from 'react-icons/bi'
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FiSettings } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import LanguageSelector from './LanguageSelector'

interface MenuItem {
  title: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  id: string;
}

interface SidebarProps {
  onSelectItem: (id: string) => void;
  selectedItem: string;
}


const framerSidebarPanel: Variants = {
  expanded: { width: '250px' },
  collapsed: { width: '81px' },
}

const framerText: Variants = {
  expanded: { opacity: 1, x: 0 },
  collapsed: { opacity: 0, x: -20 },
}

export const Sidebar: React.FC<SidebarProps> = ({ onSelectItem, selectedItem }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => setExpanded(false))
  const toggleSidebar = () => setExpanded(prev => !prev)

  const items: MenuItem[] = [
    { title: t('home'), Icon: BiHomeSmile, id: 'home' },
    { title: t('transactions'), Icon: FaMoneyBillTransfer, id: 'transactions' },
    { title: t('settings'), Icon: FiSettings, id: 'settings' },
  ]
  
  return (
    <motion.div
      ref={ref}
      className="fixed top-0 left-0 h-screen bg-black border-r-2 border-zinc-800 z-50"
      animate={expanded ? "expanded" : "collapsed"}
      variants={framerSidebarPanel}
      transition={{ duration: 0.3 }}
    >
            <div className="flex items-center justify-center p-4">
        <img src="https://saveix.com/saveixLogo.png" alt="Logo" className="w-12 h-12" /> {/* Adjust size as needed */}
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={toggleSidebar}
          className="text-xl rounded-full hover:bg-zinc-800"
          aria-label={expanded ? t('collapseSidebar') : t('expandSidebar')}
        >
          {expanded ? <AiOutlineRollback /> : <GiHamburgerMenu />}
        </button>
        </div>
      <ul className="mt-4 flex-grow">
        {items.map((item) => {
          const { title, id, Icon } = item
          const isSelected = id === selectedItem
          return (
            <li key={id}>
              <button
                onClick={() => onSelectItem(id)}
                className={`w-full flex items-center gap-4 p-4 transition-colors ${
                  expanded ? 'justify-start' : 'justify-center'
                } ${
                  isSelected 
                    ? 'bg-slate-700 text-cyan-100 bg-opacity-20' 
                    : 'hover:bg-zinc-800 text-gray-300 hover:text-cyan-100'
                }`}
              >
                <Icon className={`text-2xl ${isSelected ? 'text-cyan-100' : 'text-gray-300'}`} />
                <AnimatePresence>
                  {expanded && (
                    <motion.span
                      variants={framerText}
                      initial="collapsed"
                      animate="expanded"
                      exit="collapsed"
                      transition={{ duration: 0.2 }}
                    >
                      {title}
                    </motion.span>
                  )}
                </AnimatePresence>
              </button>
            </li>
          )
        })}
        <li>
          <div className="mt-auto">
            <LanguageSelector expanded={expanded} />
          </div>
        </li>
      </ul>

    </motion.div>
  )
}
import React, { createContext, useState, useContext, useEffect } from 'react';
import api from './services/api';

interface Goal {
  id: string;
  amount: number;
  startingDate: string;
  endingDate: string;
  description: string;
}

interface GoalsContextType {
  goals: Goal[];
  fetchGoals: () => Promise<void>;
  addGoals: (goal: Omit<Goal, 'id'>) => Promise<void>;
  deleteGoal: (goalId: string) => Promise<void>;
}

const GoalsContext = createContext<GoalsContextType | undefined>(undefined);

export const GoalsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [goals, setGoals] = useState<Goal[]>([]);

  const fetchGoals = async () => {
    try {
      const response = await api.get('/api/goals');
      setGoals(response.data);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  const addGoals = async (goal: Omit<Goal, 'id'>) => {
    try {
      const response = await api.post('/api/Goals', goal);
      setGoals(prevGoals => [...prevGoals, response.data]);
    } catch (error) {
      console.error('Error adding goal:', error);
    }
  };

  const deleteGoal = async (goalId: string) => {
    try{
      await api.delete(`/api/Goals/${goalId}`)
      setGoals(prevGoals => prevGoals.filter(goal => goal.id !== goalId));
    }catch(error){
      console.log('Error deleting goal', error);
    }
  }

  useEffect(() => {
    fetchGoals();
  }, []);

  return (
    <GoalsContext.Provider value={{ goals, fetchGoals, addGoals, deleteGoal }}>
      {children}
    </GoalsContext.Provider>
  );
};

export const useGoals = () => {
  const context = useContext(GoalsContext);
  if (context === undefined) {
    throw new Error('useGoals must be used within a GoalsProvider');
  }
  return context;
};
import React, { useMemo } from 'react';
import { Card, DonutChart, List, ListItem, Title } from '@tremor/react';
import { useTransactions } from '../TransactionContext';
import { useTranslation } from 'react-i18next';

const data = [
  { name: 'Travel', amount: 6730 },
  { name: 'IT & equipment', amount: 4120 },
  { name: 'Training & development', amount: 3920 },
  { name: 'Office supplies', amount: 3210 },
  { name: 'Communication', amount: 3010 },
];

const valueFormatter = (number: number) => `$ ${Intl.NumberFormat("us").format(number).toString()}`;

const customColors = ["slate", "violet", "emerald", "amber", "rose"];

const ExpensesChart: React.FC = () => {
  const { t } = useTranslation();
  const { transactions } = useTransactions();

  const expenseTransactions = useMemo(() => {
    return transactions.reduce((acc, transaction) => {
      if (transaction.type === 'Expense') {
        const category = transaction.category;
        const amount = Math.abs(transaction.amount);
        acc[category] = (acc[category] || 0) + amount;
      }
      return acc;
    }, {} as Record<string, number>);
  }, [transactions]);

  const chartData = useMemo(() => {
    return Object.entries(expenseTransactions).map(([name, amount]) => ({ name, amount }));
  }, [transactions]);

  return (
    <Card 
      className="max-w-lg bg-dark-tremor-background-subtle"
      decoration="none"
    >
      <Title className="text-dark-tremor-content-strong">{t('expensesByCategory')}</Title>
      <DonutChart
        className="mt-6"
        data={chartData}
        category="amount"
        index="name"
        valueFormatter={valueFormatter}
        colors={customColors}
        showAnimation={true}
      />
      <List className="mt-6">
        {chartData.map((item, index) => (
          <ListItem key={item.name} className="text-dark-tremor-content">
            <span className={`bg-${customColors[index]}-500 h-3 w-3 rounded-full mr-2`} />
            <span>{item.name}</span>
            <span className="flex-grow text-right">{valueFormatter(item.amount)}</span>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default ExpensesChart;
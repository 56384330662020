import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // The backend now sets the cookie and redirects to /dashboard
    // So we don't need to do anything here
    navigate('/dashboard');
  }, [navigate]);

  return <div>Completing authentication...</div>;
};

export default AuthCallback;